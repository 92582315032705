<!--
 * @Descripttion: 推广人列表
 * @version: 
 * @Author: dxl
 * @Date: 2023-08-20 14:26:00
 * @LastEditors: dxl 1668645906@qq.com
 * @LastEditTime: 2023-08-28 15:45:22
-->
<template>
    <div class="LedgerUserList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">推广人列表</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <el-select clearable v-model="sel_code" @change="stateChange" placeholder="请选择推广码筛选">
                    <el-option v-for="item in codeOptions" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <div class="search-item">
                    <el-select clearable v-model="sel_state" @change="stateChange" placeholder="请选择禁用状态筛选">
                        <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="search-item">
                    <el-select clearable v-model="sel_examine_state" @change="stateChange" placeholder="请选择审核状态筛选">
                        <el-option v-for="item in ExamineStateOptions" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="search-item">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                </div>
            </div>

            <!-- 表格 -->
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border stripe style="width: 100%">
                    <el-table-column align="center" prop="id" label="ID" width="50"></el-table-column>
                    <el-table-column align="center" prop="name" label="推广人备注" width="150"
                        :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="mobile" label="推广人手机号" width="150"
                        :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="openid" label="推广人openid"></el-table-column>
                    <el-table-column align="center" prop="create_timex" label="申请时间" width="170"
                        :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="promotion_code" label="推广码" width="150">
                        <template slot-scope="scope">
                            <div v-if="scope.row.promotion_code">
                                <el-tag size="medium">{{ scope.row.promotion_code }}</el-tag>
                            </div>
                            <div v-else>暂无</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="state" label="审核状态" width="100" fixed="right">
                        <template slot-scope="scope">
                                <div v-if="scope.row.examine_state==1">
                                    <el-tag type="success">通过</el-tag>
                                </div>
                                <el-tooltip placement="top" :content="scope.row.examine_msg">
                                    <div v-if="scope.row.examine_state==2">
                                        <el-tag type="danger">拒绝</el-tag>
                                    </div>
                                </el-tooltip>
                                <div v-if="scope.row.examine_state==0">未审核</div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="state" label="禁用状态" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip placement="top" :content="scope.row.state==1?'启用':'禁用'">
                                <el-switch v-model="scope.row.state" 
                                    active-color="#13ce66" 
                                    inactive-color="#ff4949"
                                    @change="changeState(scope.row)"
                                    >
                                </el-switch>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="操作" width="220" fixed="right">
                        <template slot-scope="scope">
                            <div class="operation-wrap">
                                <el-tooltip class="item" effect="dark" content="审核" placement="top">
                                    <i @click="clickSH(scope.row)" class="iconfont el-icon-s-check"></i>
                                </el-tooltip>
                                <el-tooltip class="item pointer" effect="dark" content="编辑" placement="top">
                                    <i @click="clickEdit(scope.row)" class="iconfont el-icon-edit-outline"></i>
                                </el-tooltip>
                                <el-tooltip class="item pointer" effect="dark" content="删除" placement="top">
                                    <i @click="clickDel(scope.row)" class="iconfontx el-icon-delete"></i>
                                </el-tooltip>
                                <el-tooltip class="item" effect="dark" content="配置品牌分账" placement="top">
                                    <i @click="clickCode(scope.row)" class="iconfont el-icon-s-tools"></i>
                                </el-tooltip>
                                <el-link class="link" target="_blank" :href="scope.row.pdf_link" :underline="false">
                                    <el-tooltip class="item" effect="dark" content="查看订单" placement="top">
                                        <i @click="clickOrder(scope.row)" class="iconfont el-icon-document-checked"></i>
                                    </el-tooltip>
                                </el-link>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>

                <div class="pagination-wrap">
                    <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10"
                        layout="total, prev, pager, next" :total="total">
                    </el-pagination>
                </div>
            </div>


            <!-- 编辑弹窗 -->
            <el-dialog
                :visible.sync="isShowEditInfo" 
                :before-close="handleClose"
                custom-class="dialog-wrap"
                :close-on-click-modal="false">
                <div class="title">编辑推广人</div>
                <div class="content">
                    <div class="item">
                        <div class="tit">推广人备注</div>
                        <div class="status-wrap">
                            <el-input type="text" v-model="edit_info.name" placeholder="请填写备注"></el-input>
                        </div>
                    </div>
                    <div class="item" >
                        <div class="tit-red">推广人手机（非必要不修改）</div>
                        <div class="status-wrap">
                            <el-input type="text" v-model="edit_info.mobile" placeholder="请填写推广人手机"></el-input>
                        </div>
                    </div>
                    <div class="btn item">
                        <el-button @click="submitEditInfo()" type="primary">确定</el-button>
                    </div>
                </div>
            </el-dialog>

            
             <!-- 审核弹窗 -->
             <el-dialog
             :visible.sync="isShowSH" 
             :before-close="handleClose"
             custom-class="dialog-wrap"
             :close-on-click-modal="false">
             <div class="title">审核</div>
             <div class="content">
                 <div class="item">
                     <div class="tit">审核</div>
                     <div class="status-wrap">
                        <el-radio-group v-model="SH_info.examine_state" size="small">
                            <el-radio label='1' border>通过并生成推广码</el-radio>
                            <el-radio label='2' border>拒绝</el-radio>
                        </el-radio-group>
                     </div>
                 </div>
                 <div class="item" v-if="SH_info.examine_state==2">
                     <div class="tit-red">审核不通过原因</div>
                     <div class="status-wrap">
                         <el-input type="textarea" v-model="SH_info.examine_msg" placeholder="请填写拒绝原因"></el-input>
                     </div>
                 </div>
                 <div class="btn item">
                     <el-button @click="submitSH()" type="primary">确定</el-button>
                 </div>
             </div>
         </el-dialog>


        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components: {
        AliyunUpload,
    },
    mixins: [pageMixin],
    data() {
        return {
            pageMixin_index: 'LedgerUserList',
            sel_state: '',
            stateOptions: [{
                value: 1,
                label: '开启'
            }, {
                value: 2,
                label: '禁用'
            }],
            sel_code:'',
            codeOptions: [{
                value: 1,
                label: '已有'
            }, {
                value: 2,
                label: '暂无'
            }],
            sel_examine_state:'',
            ExamineStateOptions:[{
                value: 0,
                label: '未审核'
            }, {
                value: 1,
                label: '已通过'
            },{
                value: 2,
                label: '未通过'
            }],
            loading: false,
            tableData: [],
            currentPage: 1,
            total: 0,
            keyword: '',

            isShowEditInfo:false,
            edit_id:'',
            edit_info: {
                name:'',
                mobile: ''
            },

            isShowSH:false,
            SH_id:'',
            SH_info:{
                examine_state:'1',
                examine_msg:'',
            },
        }
    },
    mounted() {
        this.getLedgerUserList();
    },
    methods: {
        //审核
        clickSH(row){
            // console.log(row);
            this.isShowSH=true;
            this.SH_id = row.id;
            this.SH_info.examine_state = ""+row.examine_state;
            this.SH_info.examine_msg = row.examine_msg;
        },
        //审核提交
        submitSH(){
            if(this.SH_info.examine_state==1){
                //通过
                this.$confirm('此操作将生成推广码，同时当前人员会加入到公司微信商户号中, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let params = {
                        id: this.SH_id,
                        examine_state: 1,
                    }
                    this.loading = true;
                    common.connect('/customerservicev1/Promoter/setPromoterExamine', params, (res) => {
                        this.loading = false;
                        this.isShowSH = false;
                        this.getLedgerUserList();
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
                
            }else if (this.SH_info.examine_state==2) {
                //拒绝
                if(this.SH_info.examine_msg){
                    
                }
                let params = {
                    id: this.SH_id,
                    examine_state: 2,
                    examine_msg: this.SH_info.examine_msg,
                }
                this.loading = true;
                common.connect('/customerservicev1/Promoter/setPromoterExamine', params, (res) => {
                    this.loading = false;
                    this.isShowSH = false;
                    this.getLedgerUserList();
                })
            } else {
                //不操作
            }
        
        },
        // 跳转订单列表页
        clickOrder(row) {
            let newPage = this.$router.resolve({
                path: 'LedgerOrderList',
                query: {
                    openid: row.openid,
                }
            });
            window.open(newPage.href, '_blank');
        },
        // 设置品牌分账金额
        clickCode(row) {
            let newPage = this.$router.resolve({
                path: 'LedgerEpList',
                query: {
                    id: row.id,
                }
            });
            window.open(newPage.href, '_blank');
        },
        submitEpAdd(){
            let params = {
                id: row.id,
                ep_id: row.id,
                price: row.id,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/setPromoterEPprice', params, (res) => {
                this.loading = false;
                this.getLedgerUserList();
            })
        },
        submitEpEdit(){
            let params = {
                id: row.id,
                ep_id: row.id,
                price: row.id,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/setPromoterEPprice', params, (res) => {
                this.loading = false;
                this.getLedgerUserList();
            })
        },
        submitEpDel(){
            let params = {
                id: row.id,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/setPromoterEPdel', params, (res) => {
                this.loading = false;
                this.getLedgerUserList();
            })
        },
        // 编辑推广员
        clickEdit(row) {
            this.isShowEditInfo = true;
            this.edit_id = row.id;
            this.edit_info.name = row.name;
            this.edit_info.mobile = row.mobile;
        },
        // 编辑推广员，保存
        submitEditInfo(){
            let params = {
                id: this.edit_id,
                name: this.edit_info.name,
                mobile: this.edit_info.mobile,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/setPromoterEdit', params, (res) => {
                this.loading = false;
                this.getLedgerUserList();
                this.isShowEditInfo = false;
            })
        },
        // 删除，推广员
        clickDel(row) {
            this.$confirm('此操作将删除该推广员，之后将无法查询关联的所有订单, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                common.connect('/customerservicev1/Promoter/setPromoterDel', { sceneId: row.id }, (res) => {
                    if (res.isSuccess == 1) {
                        this.getLedgerUserList();
                        this.$message.success(res.msg)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        //启用禁用
        changeState(row){
            let params = {
                id: row.id,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/setPromoterState', params, (res) => {
                this.loading = false;
            })
        },
        // 获取数据
        getLedgerUserList() {
            let params = {
                page: this.currentPage,
                keyword: this.keyword,
                state: this.sel_state,
                code_state: this.sel_code,
                examine_state: this.sel_examine_state,
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/getPromoterList', params, (res) => {
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        // 搜索
        clickSearch() {
            this.currentPage = 1;
            this.getLedgerUserList();
        },
        // 状态选择
        stateChange() {
            this.currentPage = 1;
            this.getLedgerUserList();
        },
        //审核状态选择
        ExamineStateChange(){
            this.currentPage = 1;
            this.getLedgerUserList();
        },
        //翻页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getLedgerUserList();
        },

        handleClose(){
            this.isShowEditInfo = false;
            this.isShowSH = false;
        },
    },
}
</script>

<style lang='scss'>
.pointer {
    cursor: pointer;
}

.LedgerUserList {
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;

    .title-wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;

        .tag {
            width: 4px;
            height: 20px;
            background: #409EFF;
            border-radius: 10px;
        }

        .title {
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }

    .case-wrap {
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;

        .screen-wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 20px;

            .search-item {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 20px;

                .el-input {
                    width: 220px;
                    margin-right: 10px;
                }
            }
        }

        .table-wrap {
            margin-top: 20px;

            .image {
                width: 40px;
                cursor: pointer;
            }

            .operation-wrap {
                .iconfont {
                    font-size: 17px;
                    padding: 0 10px;
                    cursor: pointer;
                }

                .iconfontx {
                    font-size: 17px;
                    color: red;
                    padding: 0 10px;
                    cursor: pointer;
                }

                .link {
                    cursor: pointer;
                    color: #409EFF;
                }
            }

            .pagination-wrap {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }

    .dialog-wrap {
        width: 540px;

        .title {
            text-align: center;
            font-size: 18px;
            color: #000;
        }

        .content {
            margin-left: 20px;

            .el-input {
                width: 500px;
            }

            .el-textarea {
                width: 500px;
            }

            .item {
                padding-bottom: 20px;

                .tit {
                    padding-bottom: 20px;
                    color: #000;
                }
                .tit-red {
                    padding-bottom: 20px;
                    color: #da0505;
                }

                .el-button {
                    width: 500px;
                }

                .upload-wrap {
                    .fileList-box {
                        position: relative;
                        width: 250px;
                        border: 1px solid #eee;
                        border-radius: 6px;
                        padding: 20px 20px;

                        .delFile {
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            background: rgba(0, 0, 0, 0.2);
                            padding: 0 5px;
                            border-radius: 10px;
                            color: #fff;
                            cursor: pointer;

                        }

                        .link {
                            width: 230px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;

                            .icon {
                                display: flex;
                                flex-wrap: wrap;
                                align-items: baseline;

                                .iconfont {
                                    font-size: 14px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .btn-wrap {
            display: flex;
            justify-content: center;
        }
    }

    .el-dialog {
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding-bottom: 10px;
    }
}</style>
